import { Injectable } from '@angular/core';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { onBackgroundMessage } from 'firebase/messaging/sw';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './auth.service';
import { FirebaseApp } from '@angular/fire/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  userId;
  constructor(
    private authService: AuthenticationService,
    private test: FirebaseApp,
    private fdb: AngularFirestore
  ) {
    this.userId = JSON.parse(localStorage.getItem(environment.LOCAL_USER))?._id;
  }

  requestPermission() {
    navigator.serviceWorker
      .register('../../../firebase-messaging-sw.js')
      .then(async (register) => {
        const messaging = getMessaging(this.test);
        await getToken(messaging, { vapidKey: environment.firebase.vapidKey })
          .then((currentToken) => {
            if (currentToken) {
              localStorage.setItem(environment.FIREBASE_TOKEN, currentToken);
              this.updateToken(currentToken);
            }
          })
          .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          });
      });
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {});
  }

  updateToken(token: string) {
    const user = this.authService.userProfileSubject.getValue();
    user.firebaseToken = user.firebaseToken ? user.firebaseToken : [];
    if (user.firebaseToken.includes(token)) {
      return;
    }
    user.firebaseToken.push(token);
    this.authService.updateProfile(user.userId, user).subscribe((res) => {
      console.log(res);
    });
  }

  getNotificationUnread() {
    return this.fdb.collection('notifications').doc(this.userId).valueChanges();
  }

  getAllNotifications() {
    return this.fdb
      .collection('notifications')
      .doc(this.userId)
      .collection('userNotification')
      .valueChanges();
  }
  updateUnread() {
    return this.fdb
      .collection('notifications')
      .doc(this.userId)
      .update({ unread: 0 });
  }

  updateMessageIsRead(notificationId) {
    return this.fdb
      .collection('notifications')
      .doc(this.userId)
      .collection('userNotification')
      .doc(notificationId)
      .update({ isRead: true });
  }
}

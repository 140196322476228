export const environment = {
  production: true,
  API_URL: 'https://sofrtiapi.azurewebsites.net',
  TOKEN_NAME: 'SofkITCHENToken',
  LOCAL_USER: 'SofkITCHENUser',
  USER_TYPE: 'KitchenProfile',
  firebase: {
    projectId: 'sofrti-16020',
    appId: '1:1077440221931:web:abc60d02193bcdcd5ba4ae',
    storageBucket: 'sofrti-16020.appspot.com',
    apiKey: 'AIzaSyCAsfiOTAdZ6vfZ0axHXisU0S8FdguouoE',
    authDomain: 'sofrti-16020.firebaseapp.com',
    messagingSenderId: '1077440221931',
    measurementId: 'G-GRZF5242W3',
    vapidKey:
      'BGUgPGHJSzUk2PSXcs1hbzVSEDTQxhuqKqS4j6SxMp1nFi0MMGA1tHUq5QVg4VwiN-Gv1ZtFLUXF87lRrZHRTJc',
  },
  FIREBASE_TOKEN: 'SofkITCHENFirebaseToken',
};

<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-sofrty.svg" alt="" height="22" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-sofrty.svg" alt="" height="17" />
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-sofrty.svg" alt="" height="40" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-sofrty.svg" alt="" height="60" />
          </span>
        </a>
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 d-lg-none header-item"
        (click)="toggleMenubar()"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>

    <div class="d-flex">
      <!-- full screen -->
      <div class="dropdown d-none d-lg-inline-block me-1">
        <button
          type="button"
          class="btn header-item noti-icon"
          data-toggle="fullscreen"
          (click)="fullscreen()"
        >
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <!-- notifications -->
      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item noti-icon"
          id="page-header-notifications-dropdown"
          ngbDropdownToggle
          (click)="updateNotificationUnread()"
        >
          <i class="bx bx-bell bx-tada"></i>
          <span class="badge bg-danger rounded-pill" *ngIf="unreadNotification">{{unreadNotification}}</span>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          ngbDropdownMenu
          aria-labelledby="page-header-notifications-dropdown"
        >
          <div class="p-3 d-flex justify-content-center">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ "HEADER.NOTIFICATIONS.TITLE" | translate }}
                </h6>
              </div>
            </div>
          </div>
          <ngx-simplebar style="position: relative; height: 230px">
            <a class="text-reset notification-item" *ngFor="let notification of notifications" (click)="navigateToOrder(notification)">
              <div class="media" [class.unread]="!notification.isRead">
                <div class="avatar-xs me-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                  >
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                  طلب جديد رقم ({{notification.orderNumber}})
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      لديك طلب جديد من العميل {{notification.clientName}}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ notification.date.toDate() | date:'short' }}
                    </p>
                  </div>
                </div>
              </div>
            </a>

          </ngx-simplebar>
        </div>
      </div>

      <!-- user -->
      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          id="page-header-user-dropdown"
          ngbDropdownToggle
        >
          <img
            class="rounded-circle header-profile-user"
            [src]="profile?.image"
            onerror="this.src='assets/images/logo-sofrty.svg'"
            alt="Header Avatar"
          />
          <span class="d-none d-xl-inline-block ms-1">{{
            profile?.name ? profile.name : "user"
          }}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" routerLink="/profile"
            ><i class="bx bx-user font-size-16 align-middle me-1"></i>
            {{ "HEADER.LOGIN.PROFILE" | translate }}</a
          >

          <a class="dropdown-item" routerLink="/terms&conditions"
            ><i class="bx bxs-file-doc font-size-16 align-middle me-1"></i
            >{{ "HEADER.LOGIN.TERMSANDCONDITIONS" | translate }}</a
          >

          <a class="dropdown-item d-block" href="javascript: void(0);">
            <i class="bx bxs-user-rectangle font-size-16 align-middle me-1"> </i
            >{{ "HEADER.LOGIN.HOW_WE_ARE" | translate }}
          </a>

          <a class="dropdown-item" href="javascript: void(0);"
            ><i class="bx bx-stats font-size-16 align-middle me-1"></i>
            {{ "HEADER.LOGIN.HOWITWORK" | translate }}</a
          >

          <a class="dropdown-item" href="javascript: void(0);"
            ><i class="bx bx-phone-call font-size-16 align-middle me-1"></i>
            {{ "HEADER.LOGIN.CALL_US" | translate }}</a
          >

          <div class="dropdown-divider"></div>
          <a
            class="dropdown-item text-danger"
            href="javascript: void(0);"
            (click)="logout()"
            ><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ "HEADER.LOGIN.LOGOUT" | translate }}</a
          >
        </div>
      </div>

      <!-- <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="onSettingsButtonClicked()">
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div> -->
    </div>
  </div>
</header>
<div class="topnav">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
      <div class="collapse navbar-collapse active" id="topnav-menu-content">
        <ul class="navbar-nav">
          <ng-container *ngFor="let item of menuItems">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle arrow-none"
                *ngIf="!item.subItems"
                href="javascript: void(0);"
                id="topnav-components"
                routerLinkActive="active"
                [routerLink]="item.link"
                role="button"
              >
                <i class="bx {{ item.icon }} me-2"></i
                >{{ item.label | translate }}
                <div class="arrow-down" *ngIf="hasItems(item)"></div>
              </a>

              <a
                *ngIf="item.subItems"
                class="nav-link dropdown-toggle arrow-none"
                (click)="onMenuClick($event)"
                href="javascript: void(0);"
                id="topnav-components"
                role="button"
              >
                <i class="bx {{ item.icon }} me-1"></i>
                {{ item.label | translate }}
                <div class="arrow-down"></div>
              </a>

              <div
                class="dropdown-menu"
                [ngClass]="{
                  'dropdown-mega-menu-xl px-2': item.subItems.length > 11
                }"
                aria-labelledby="topnav-dashboard"
                *ngIf="hasItems(item)"
              >
                <ng-template
                  ngFor
                  let-i="index"
                  let-subitem
                  [ngForOf]="item.subItems"
                >
                  <a
                    *ngIf="item.subItems.length < 11 && !hasItems(subitem)"
                    class="col dropdown-item side-nav-link-ref"
                    [routerLink]="subitem.link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                  >
                    {{ subitem.label | translate }}
                  </a>
                  <div *ngIf="item.subItems.length > 11">
                    <div *ngIf="i % 3 == 0" class="row">
                      <div class="col-lg-4">
                        <a
                          class="dropdown-item side-nav-link-ref"
                          routerLinkActive="active"
                          [routerLink]="subitem.link"
                          >{{ item.subItems[i].label | translate }}</a
                        >
                      </div>
                      <div class="col-lg-4">
                        <a
                          class="dropdown-item side-nav-link-ref"
                          routerLinkActive="active"
                          [routerLink]="item.subItems[i + 1]?.link"
                          >{{ item.subItems[i + 1]?.label | translate }}</a
                        >
                      </div>
                      <div class="col-lg-4">
                        <a
                          class="dropdown-item side-nav-link-ref"
                          routerLinkActive="active"
                          [routerLink]="item.subItems[i + 2]?.link"
                          >{{ item.subItems[i + 2]?.label | translate }}</a
                        >
                      </div>
                    </div>
                  </div>

                  <div class="dropdown" *ngIf="hasItems(subitem)">
                    <a
                      class="dropdown-item dropdown-toggle"
                      href="javascript: void(0);"
                      (click)="onMenuClick($event)"
                      >{{ subitem.label | translate }}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu">
                      <ng-template
                        ngFor
                        let-subSubitem
                        [ngForOf]="subitem.subItems"
                      >
                        <a
                          class="dropdown-item side-nav-link-ref"
                          *ngIf="!hasItems(subSubitem)"
                          href="javascript: void(0);"
                          [routerLink]="subSubitem.link"
                          routerLinkActive="active"
                          >{{ subSubitem.label | translate }}</a
                        >
                        <div class="dropdown" *ngIf="hasItems(subSubitem)">
                          <a
                            class="dropdown-item dropdown-toggle"
                            href="javascript: void(0);"
                            (click)="onMenuClick($event)"
                            >{{ subSubitem.label | translate }}
                            <div class="arrow-down"></div>
                          </a>
                          <div class="dropdown-menu">
                            <ng-template
                              ngFor
                              let-subSubSubitem
                              [ngForOf]="subSubitem.subItems"
                            >
                              <a
                                class="dropdown-item side-nav-link-ref"
                                href="javascript: void(0);"
                                [routerLink]="subSubSubitem.link"
                                routerLinkActive="active"
                                >{{ subSubSubitem.label | translate }}</a
                              >
                            </ng-template>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </ng-template>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </nav>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { User } from '../models/auth.models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  userProfileSubject = new BehaviorSubject<any>({});
  userProfile = this.userProfileSubject.asObservable();

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(sessionStorage.getItem(environment.LOCAL_USER))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  get LoggedInUser() {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return this.http
      .post<any>(environment.API_URL + '/api/auth/login', {
        email,
        password,
        type: environment.USER_TYPE,
      })
      .pipe(
        map((res) => {
          let user = res.data;
          if (user.type == environment.USER_TYPE) {
            // login successful if there's a jwt token in the response
            console.log(user);
            if (user && user.token) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem(environment.TOKEN_NAME, user.token);
              localStorage.setItem(
                environment.LOCAL_USER,
                JSON.stringify(user)
              );
              this.currentUserSubject.next(user);
            }
            return user;
          } else {
            return false;
          }
        })
      );
  }

  register(user) {
    return this.http
      .post<any>(environment.API_URL + '/api/auth/register', {
        ...user,
        type: environment.USER_TYPE,
      })
      .pipe(
        map((res) => {
          let user = res.profile;
          // login successful if there's a jwt token in the response
          console.log(user);

          return user;
        })
      );
  }

  getuserProfile() {
    this.http
      .get(environment.API_URL + '/api/auth/profile')
      .pipe(map((res: any) => res.data))
      .subscribe((res) => {
        console.log('getuserProfile', res);
        this.userProfileSubject.next(res);
      });
  }

  updateProfile(id, body) {
    body.type = environment.USER_TYPE;
    return this.http.post(
      environment.API_URL + '/api/auth/profile/update/' + id,
      body
    );
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(environment.LOCAL_USER);
    localStorage.removeItem(environment.FIREBASE_TOKEN);
    this.currentUserSubject.next(null);
    this.unregisterFirebaseToken();
  }

  getProfile() {
    return this.http.get(environment.API_URL + '/api/auth/profile').pipe(
      map((res: any) => {
        this.userProfileSubject.next(res.data);
        return res.data;
      })
    );
  }

  unregisterFirebaseToken() {
    const currentToken = localStorage.getItem(environment.FIREBASE_TOKEN);
    const user = this.userProfileSubject.getValue();
    if (!user.firebaseToken) {
      return;
    }
    const currentTokenIndex = user.firebaseToken.indexOf(currentToken);

    user.firebaseToken.splice(currentTokenIndex, 1);
    this.updateProfile(user.userId, user)
      .pipe(
        finalize(() => {
          localStorage.removeItem(environment.TOKEN_NAME);
          location.reload();
        })
      )
      .subscribe((res) => {
        console.log(res);
      });
  }
}

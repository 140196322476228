import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'NEWMENU.LISTS',
    icon: 'bxs-customize',
    link: '/menus',
  },
  {
    label: 'NEWMENU.ORDERS',
    icon: 'bxs-dish',
    link: '/orders',
  },
  {
    label: 'NEWMENU.ORDERSNEW',
    icon: 'bxs-cart',
    link: '/orders/list-v2',
  },
  {
    label: 'NEWMENU.SCHEDULE',
    icon: 'bx-calendar',
    link: '/schedule',
  },
  {
    label: 'NEWMENU.DASHBOARD',
    icon: 'bx-slider-alt',
    link: '/dashboard',
  },
];
